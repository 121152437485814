import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AST_SimpleStatement } from 'terser';

const RequestDemoForm = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [clientAmt, setClientAmt] = useState('');
  const [compState, setCompState] = useState('');
  const [sending, setSending] = useState('false');

  const sendData = {
    subject: 'Demo Request From: ' + name,
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    companyName: company,
    message: message,
    clientAmt: clientAmt,
    compState: compState,
    dest: 'sales'

  }



  const headers = {
    'Content-Type': 'application/json'
  }

  const DemoRequestButton = event => {
  if (sending === 'false') {
      return <button type="submit" className="btn btn-secondary transition-3d-hover">Let's Start Working Together</button>;
    } else if (sending === 'true') {
      return <button type="button" className="btn btn-success disabled">Sending Demo Request</button>;
    } else if (sending === 'sent') {
      return <button type="button" className="btn btn-info disabled">Your Request Has Been Sent</button>;
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    setSending('true');


    axios.post(`https://84fdl5xpqd.execute-api.us-west-2.amazonaws.com/dev`, sendData, { headers: headers })
      .then(res => {
        console.log(res);
        setSending('sent');
        setTimeout(() => {setSending('false');}, 3000)
      })
  }

useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://assets.calendly.com/assets/external/widget.js';
  script.async = true;
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  }
}, []);

  return (
    <>
      {/* Hire Us Form Section */}
<div className="calendly-inline-widget" data-url="https://calendly.com/sales-eli?hide_gdpr_banner=1" style={{minWidth: '320px', height: '700px'}}></div>

      {/* End Hire Us Form Section */}
    </>
  )
}

export default RequestDemoForm
