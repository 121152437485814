import React from 'react'
import axios from 'axios';

// Components
import Layout from '~components/layout'
import RequestDemoForm from '../components/request-demo-form';

// Images
import appDevelopment from '~images/app_development.svg'
import bgElement from '~images/bg-elements-7.svg'
import wave from '~images/wave_4_bottom_teal.svg'
import wave2 from '~images/wave-1-bottom-sm.svg'

const Demo = () => {


  const headers = {
    'Content-Type': 'application/json'
  }

  return (
    <Layout>
      <main id="content" role="main">
        {/* Hire Us Title Section */}
        <div id="SVGheroBgElements" className="position-relative gradient-half-qvr-v3 z-index-2 overflow-hidden">
          <div className="container space-top-2 space-bottom-3 space-top-md-5 space-bottom-lg-5">
            <div className="w-md-80 w-lg-50 text-center mx-md-auto">
              <h1 className="text-white">Request Your <span className="font-weight-semi-bold">Free</span> Demo</h1>
            </div>
          </div>

          {/* SVG Background Shapes */}
          <div className="w-100 w-sm-75 w-md-50 content-centered-y z-index-n1 ml-9">
            <figure className="ie-bg-elements-2">
              <img className="js-svg-injector" src={bgElement} aria-hidden="true" alt=""
                data-parent="#SVGheroBgElements" />
            </figure>
          </div>
          {/* End SVG Background Shapes */}

          {/* SVG Bottom Shape */}
          <figure className="position-absolute right-0 bottom-0 left-0">
            <img className="js-svg-injector" src={wave} aria-hidden="true" alt=""
              data-parent="#SVGheroBgElements" />
          </figure>
          {/* End SVG Bottom Shape */}
        </div>
        {/* End Hire Us Title Section */}

        <RequestDemoForm />

        {/* Features Section */}
        <div id="SVGelements" className="svg-preloader">
          <div className="gradient-half-primary-v3">
            <div className="container space-bottom-2">
              <div className="w-60 mx-auto">
                {/* SVG Icon */}
                <figure className="ie-app-development">
                  <img src={appDevelopment} alt="SVG Illustration" style={{ width: `100%` }}/>
                </figure>
                {/* End SVG Icon */}
              </div>
            </div>
          </div>

          {/* SVG Background */}
          <figure className="position-absolute right-0 bottom-0 left-0">
            <img style={{ width: '100%', height: '140px' }} src={wave2} alt="" aria-hidden="true" />
          </figure>
          {/* End SVG Background */}
        </div>
        {/* End Features Section */}
      </main>
      {/* ========== END MAIN ========== */}
    </Layout>
  )
}

export default Demo
